import { FC } from 'react'
import { Box } from '@mui/material'
import { FileUpload } from '../../types/File'
import { AUDIO_EXTENSIONS, CODE_EXTENSIONS, IMAGE_EXTENSIONS } from '../../constants/FileConstants'
import { ReactComponent as FormatIconCSV } from '../../images/format-csv.svg'
import { ReactComponent as FormatIconDOC } from '../../images/format-doc.svg'
import { ReactComponent as FormatIconJSON } from '../../images/format-json.svg'
import { ReactComponent as FormatIconPDF } from '../../images/format-pdf.svg'
import { ReactComponent as FormatIconPPTX } from '../../images/format-pptx.svg'
import { ReactComponent as FormatIconTXT } from '../../images/format-txt.svg'
import { ReactComponent as FormatIconXLS } from '../../images/format-xls.svg'
import DocumentIcon from '@mui/icons-material/Description'
import ImageIcon from '@mui/icons-material/Image'
import CodeIcon from '@mui/icons-material/Code'
import AudioIcon from '@mui/icons-material/AudioFile'
import './FileUploadChipIcon.scss'

const CUSTOM_FORMAT_MAP = {
    csv: FormatIconCSV,
    doc: FormatIconDOC,
    docx: FormatIconDOC,
    json: FormatIconJSON,
    pdf: FormatIconPDF,
    pptx: FormatIconPPTX,
    txt: FormatIconTXT,
    xls: FormatIconXLS,
}

const GENERIC_FORMAT_MAP = {
    ...AUDIO_EXTENSIONS.reduce((prev, extension) => ({ ...prev, [extension]: AudioIcon}), {}),
    ...CODE_EXTENSIONS.reduce((prev, extension) => ({ ...prev, [extension]: CodeIcon}), {}),
    ...IMAGE_EXTENSIONS.reduce((prev, extension) => ({ ...prev, [extension]: ImageIcon}), {}),
}

type Props = {
    fileUpload: FileUpload
}

export const FileUploadChipIcon: FC<Props> = ({
    fileUpload
}) => {
    const [format] = fileUpload.metadata.name.split('.').slice(-1).map(item => item.toLowerCase())
    const GenericComponent = GENERIC_FORMAT_MAP[format] ?? DocumentIcon
    const Component = CUSTOM_FORMAT_MAP[format] ?? GenericComponent
    const formatClassName = `FileUploadIcon_${format}`

    return (
        <Box className={`FileUploadIcon ${formatClassName}`}>
            <Component/>
        </Box>
    )
} 