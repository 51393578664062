import {FC} from 'react'
import {Box, Button, Divider, IconButton, Tooltip, TooltipProps} from '@mui/material'
import {PromptChatDetail} from '../../types/PromptChatDetail'
import {useToggleDrawerContext} from '../../context/ToggleDrawerContext'
import SettingsIcon from '@mui/icons-material/SettingsOutlined'
import SaveSummarizedPromptIcon from '@mui/icons-material/LibraryBooksOutlined'
import SaveChatIcon from '@mui/icons-material/SaveOutlined'
import './TaskBar.scss'

const tooltipClasses: TooltipProps['classes'] = {
    tooltip: 'TaskBar_Tooltip'
}

type Props = {
    promptChatDetails: PromptChatDetail
    isPromptOwnedByOther: boolean
    onOpenAdvancedSettings: () => void
}

export const CustomTaskBar: FC<Props> = ({
    promptChatDetails, 
    isPromptOwnedByOther, 
    onOpenAdvancedSettings
}) => {

    const {toggleDrawer} = useToggleDrawerContext()
    const saveButtonTooltipText = isPromptOwnedByOther ? 'You can\'t edit fields in a prompt that you are not the owner of' : 'Save summarized prompt'

    return (
        <Box className='TaskBar_Container'>
            <Button className='TaskBar_Button'
                size='small'
                startIcon={<SaveChatIcon/>}
                disabled={!promptChatDetails.messages.length}
                onClick={toggleDrawer(true, 'SAVE_CHAT_HISTORY_DRAWER')}>Save chat</Button>
            <Divider orientation='vertical' flexItem/>
            <Tooltip title={saveButtonTooltipText} classes={tooltipClasses}>
                <span>
                    <IconButton className='TaskBar_IconButton'
                        size='small'
                        disabled={!promptChatDetails.messages.length || isPromptOwnedByOther}
                        onClick={toggleDrawer(true, 'SAVE_CHAT_COMPILATION_DRAWER')}>
                        <SaveSummarizedPromptIcon fontSize='small'/>
                    </IconButton>
                </span>
            </Tooltip>
            <Divider orientation='vertical' flexItem/>
            <Tooltip title='settings' classes={tooltipClasses}>
                <span>
                    <IconButton className='TaskBar_IconButton'
                        size='small'
                        disabled={!promptChatDetails.messages.length}
                        onClick={onOpenAdvancedSettings}>
                        <SettingsIcon fontSize='small'/>
                    </IconButton>
                </span>
            </Tooltip>
        </Box>
    )
}
