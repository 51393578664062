import {v4 as uuidv4} from 'uuid'
import {FileId, FileMetadata, FileUpload} from '../types/File'
import {hasProp, isEqual, not} from './genericUtils'
import {nowInSeconds} from './dateUtils'
import { AIModelID } from '../types/AiModel'

export const hasId = (id: FileId) => (fileUpload: FileUpload): boolean =>
    fileUpload.metadata.id === id

export const hasModelId = (modelId: AIModelID) => (fileUpload: FileUpload): boolean =>
    fileUpload.metadata.modelId === modelId

/** A fileUpload is considered binded once it has a conversationId/messageId */
export const isBinded = (fileUpload: FileUpload): boolean => 
    fileUpload.metadata.conversationId !== undefined && fileUpload.metadata.messageId !== undefined

/** Checks if a fileUpload is not binded but is associated to a model */
export const isModelUnbinded = (modelId: AIModelID) => (fileUpload: FileUpload): boolean =>
    hasModelId(modelId)(fileUpload) && !isBinded(fileUpload)

export const areFileUploadsMatch = (fileUploadA: FileUpload) => (fileUploadB: FileUpload): boolean =>
    fileUploadA.metadata.id === fileUploadB.metadata.id

export const areFileUploadsMatchChanged = (fileUploadA: FileUpload) => (fileUploadB: FileUpload): boolean =>
    areFileUploadsMatch(fileUploadA)(fileUploadB) && (fileUploadA.state !== fileUploadB.state || !isEqual(fileUploadA.metadata, fileUploadB.metadata))

export const createFileUpload = (file: File, modelId?: AIModelID, ownerId?: string, conversationId?: string, messageId?: string): FileUpload => ({
    file,
    state: 'idle',
    metadata: {
        id: `${uuidv4()}--${file.name ?? ''}`,
        name: file.name,
        timestamp: nowInSeconds(),
        modelId,
        ownerId,
        conversationId,
        messageId,
    }
})

export const getUnbindedUploadedFiles = (files: FileUpload[]): FileUpload[] => files
    .filter(not(isBinded))
    .filter(hasProp('state', 'completed'))

export const isMetadata = (file: FileMetadata | FileUpload): file is FileMetadata =>
    !('metadata' in file)

export const getMetadata = (file: FileMetadata | FileUpload): FileMetadata =>
    isMetadata(file) ? file : file.metadata

export const getState = (file: FileUpload | FileMetadata): string =>
    'state' in file ? file.state : ''

/**
 * Format bytes as human-readable text.
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 * @return Formatted string.
 */
export const getHumanReadableFileSize = (bytes: number, si=false, dp=1): string => {
    const thresh = si ? 1000 : 1024;
  
    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }
  
    const units = si 
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;
  
    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
  
    return bytes.toFixed(dp) + ' ' + units[u];
}

export const isFileSizeValid = (file: File, maxSizeMB: number = 200): boolean => {
    return file.size <= maxSizeMB * 1024 * 1024
}
