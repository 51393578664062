import {Button, TextField, Typography} from '@mui/material'
import Box from '@mui/material/Box'
import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'
import {usePaymentContext} from '../../../context/PaymentContext'
import {ChangeEvent} from 'react'
import './SeatsCountInput.scss'
import {TrackActionEvent} from '../../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'

interface SeatsCountInputProps {
    trackingOrigin: string
    isChangePlan?: boolean
    nextPeriodSeats?: number
}

export const SeatsCountInput = ({trackingOrigin, isChangePlan, nextPeriodSeats}: SeatsCountInputProps) => {

    const {user} = useUser()
    const {seats, setSeats} = usePaymentContext()

    const increaseSeats = () => {
        TrackActionEvent(trackingOrigin, user?.externalId ?? user?.id, {action: 'increaseSeats'})
        setSeats(previousSeatsNumber => {
            if (!previousSeatsNumber) return 1
            return previousSeatsNumber + 1
        })
    }

    const decreaseSeats = () => {
        TrackActionEvent(trackingOrigin, user?.externalId ?? user?.id, {action: 'decreaseSeats'})
        setSeats(previousSeatsNumber => {
            if (!previousSeatsNumber) return 1
            return previousSeatsNumber > 1 ? previousSeatsNumber - 1 : 1
        })
    }

    const seatsChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        const seatsNumber = Number(event.target.value)
        if (seatsNumber < 1) return setSeats(1)
        setSeats(seatsNumber)
    }

    return <Box className='planContentSeatsRow'>
        <Typography className='seatsCount'>Number of seats</Typography>
        <Box className='planContentSeatsInput'>
            <TextField variant='outlined' placeholder='Number of seats' value={isChangePlan ? nextPeriodSeats : seats}
					   onChange={seatsChangeHandler} disabled={isChangePlan}/>
            {isChangePlan ? <></> : <>
                <Box className='buttonWrapper'>
                    <Button className='subtractButton' variant='outlined' onClick={decreaseSeats}>
                            <RemoveIcon className='subtractIcon'/>
                    </Button>
                    <Button className='addButton' variant='outlined' onClick={increaseSeats}>
                        <AddIcon className='addIcon'/>
                    </Button>
                </Box>
            </>}
        </Box>
    </Box>
}