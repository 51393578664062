import {
	GROWTH_AWS_PRICE_ID,
	GROWTH_MONTHLY_PRICE_ID,
	GROWTH_YEARLY_PRICE_ID,
	PRICES_INFO_MAP,
	SCALE_MONTHLY_PRICE_ID,
	TRIAL_PRICE_ID,
	ULTIMATE_MONTHLY_PRICE_ID
} from '../constants/StripeConstants'
import {
	PaidPlan,
	Period,
	Plan,
	Subscription,
	SubscriptionSchedule,
	SubscriptionSchedulePhaseItem,
	SubscriptionSchedulePhase,
	CustomerPaymentCard
} from '../types/Stripe'
import {PLAN_HIERARCHY} from '../constants/LicenseConstants'
import {formatAmount} from './currencyUtils'
import {hasProp} from './genericUtils'
import {formatDateStringToLocaleDate} from '../helpers/DateHelpers'
import {SubscriptionUpdateType} from '../types/Subscription'

export const getPlanAmount = (plan: Plan | undefined, period: Period | undefined, seats: number = 0): number => {
	if (!plan || !period || seats < 1) return 0

	const [amount] = PRICES_INFO_MAP[plan][period].priceBrackets.reduce(([amount, limit], { price, upperSeatLimit }) =>
		seats > limit
			? [amount + (Math.min(seats, upperSeatLimit) - limit) * price, upperSeatLimit] 
		  	: [amount, limit] 
	  	, [0, 0])

	return amount
}

export const getPlanByPriceId = (priceId: string): Plan => {
	switch (priceId) {
		case TRIAL_PRICE_ID:
			return 'trial'
		case GROWTH_YEARLY_PRICE_ID:
		case GROWTH_MONTHLY_PRICE_ID:
		case GROWTH_AWS_PRICE_ID:
			return 'growth'
		case ULTIMATE_MONTHLY_PRICE_ID:
			return 'ultimate'
		default:
			return 'scale'
	}
}

export const getPeriodByPriceId = (priceId: string): Period => {
	if ([GROWTH_MONTHLY_PRICE_ID, SCALE_MONTHLY_PRICE_ID, ULTIMATE_MONTHLY_PRICE_ID].includes(priceId)) return 'monthly'
	return 'annual'
}

export const isPeriodUpdate = (currentPlan: Plan, updatedPlan: Plan, currentPeriod: Period, updatedPeriod: Period): boolean =>
	currentPlan === updatedPlan && currentPeriod !== updatedPeriod

export const isSeatsUpgrade = (oldSeats: number, newSeats: number): boolean =>
	newSeats > oldSeats

export const isPlanUpgrade = (currentPlan: Plan, updatedPlan: Plan): boolean =>
	PLAN_HIERARCHY.indexOf(updatedPlan) > PLAN_HIERARCHY.indexOf(currentPlan)

export const isUpgradeSeatsOrPlan = (currentPlan: Plan, updatedPlan: Plan, oldSeats?: number, newSeats?: number): boolean =>
	(newSeats !== undefined && oldSeats !== undefined && isSeatsUpgrade(oldSeats, newSeats)) || isPlanUpgrade(currentPlan, updatedPlan)

// Stripe expects the amount to be in the currency's subunit, which for most currencies means cents
export const toRoundedSubunit = (amount: number): number => Math.round(amount * 100)

export const getPaymentPrice = (seats: number, plan: Plan, period: Period) =>
	formatAmount(getPlanAmount(plan, period, seats))

export const getPlanPriceId = (plan: Plan | PaidPlan, period?: Period): string => {
	if (!period) return TRIAL_PRICE_ID!
	return PRICES_INFO_MAP[plan][period].priceId!
}

export const findNextPhaseItem = (schedule: SubscriptionSchedule): SubscriptionSchedulePhaseItem | undefined =>
	schedule.phases.reduce<SubscriptionSchedulePhase | undefined>((prev, phase) => {
		if (prev === undefined) {
			return phase
		} else if (phase.start_date >= (schedule.current_phase?.end_date ?? Number.POSITIVE_INFINITY) && phase.start_date <= (prev.end_date ?? Number.POSITIVE_INFINITY)) {
			return phase
		} else {
			return prev
		}
	}, undefined)?.items?.[0]

export const getNextPeriodInfo = (subscription: Subscription, schedules: SubscriptionSchedule[]): {payment: string, period: Period, plan: Plan, seats: number} => {
    const schedule = getActiveSubscriptionSchedule(subscription, schedules)
    const nextPhase = schedule ? findNextPhaseItem(schedule) : undefined
    const seats = nextPhase?.quantity ?? subscription.quantity
    const plan = getPlanByPriceId(nextPhase?.plan ?? subscription.plan.id)
    const period = getPeriodByPriceId(nextPhase?.plan ?? subscription.plan.id)

    return {
		payment: getPaymentPrice(seats, plan, period),
		period,
		plan,
		seats
	}
}

export const getActiveSubscriptionSchedule = (subscription: Subscription, schedules: SubscriptionSchedule[]): SubscriptionSchedule | undefined =>
	schedules
		.filter(hasProp('subscription', subscription.id))   // Filter only associated to the subscription
		.filter(hasProp('status', 'active'))?.[0]           // Filter only active

export const isOnePhaseSchedule = (schedule: SubscriptionSchedule): boolean =>
	schedule.phases.length === 1

export const getMonthsAuditLimitBasedOnSubscriptionPlan = (subscription?: Subscription): number => {
	if (!subscription) return 0
	return PRICES_INFO_MAP[getPlanByPriceId(subscription.plan.id)].maxMonthsAudit
}

export const isCardExpired = (card: CustomerPaymentCard): boolean => {
	const currentDate = new Date()
	const currentYear = currentDate.getFullYear()
	const currentMonth = currentDate.getMonth() + 1

	return card.exp_year <= currentYear && card.exp_month < currentMonth
}

export const getSubscriptionUpdateModalCopy = ({subscription, plan, oldSeats, updatedSeats, currentPeriod, nextPeriod}: {
	subscription: Subscription
	plan: Plan
	oldSeats: number
	updatedSeats: number
	currentPeriod: Period
	nextPeriod?: Period
}): string[] => {
	const nextBillingCycleDate = formatDateStringToLocaleDate(subscription.current_period_end * 1000)
	const planAmount = formatAmount(getPlanAmount(plan, nextPeriod, oldSeats))

	const changeType = determineChangeType(oldSeats, updatedSeats, currentPeriod, nextPeriod)
	const seatsRemoved = oldSeats && updatedSeats ? oldSeats - updatedSeats : 0

	return changeType ? getMessageForSubscriptionUpdateType(changeType, {
		nextBillingCycleDate,
		planAmount,
		currentPeriod,
		nextPeriod,
		seatsRemoved
	}) : ['']
}

const getMessageForSubscriptionUpdateType = (updateType: SubscriptionUpdateType, params: {
	nextBillingCycleDate: string
	planAmount: string
	currentPeriod: Period
	seatsRemoved: number
	nextPeriod?: Period
}): string[] => {
	const {nextBillingCycleDate, planAmount, currentPeriod, nextPeriod, seatsRemoved} = params

	const SUBSCRIPTION_MODAL_MESSAGES: Record<string, string[]> = {
		periodChangeAndSeatsDowngrade: [
			`You have made multiple changes to your subscription, the changes will be reflected on ${nextBillingCycleDate}, you will be billed ${planAmount}:`,
			`1. Your subscription will change from ${currentPeriod} to ${nextPeriod} billing.`,
			`2. ${seatsRemoved} seat(s) will be removed from your plan. If the number of users exceeds the new limit on ${nextBillingCycleDate}, the users access will be removed. Users are removed starting with the most recent additions, until their account does not exceed the limit.`,
			'Select Confirm to apply the changes.'],
		periodChangeAndSeatsUpgrade: [
			`You have made multiple changes to your subscription, the changes will be reflected on ${nextBillingCycleDate}, you will be billed ${planAmount}:`,
			`1. Your subscription will change from ${currentPeriod} to ${nextPeriod} billing.`,
			`2. Your seat change will be applied now. The updated cost, adjusted to reflect the time used, will appear on your next bill.`,
			'Select Confirm to apply the changes.'
		],
		seatsUpgrade: ['Your seat change will be applied now. The updated cost, adjusted to reflect the time used, will appear on your next bill. Select Confirm to apply this change.'],
		periodChange: [`Your subscription will change from ${currentPeriod} to ${nextPeriod}. You will be charged ${planAmount} on ${nextBillingCycleDate}. Select Confirm to apply this change.`],
		seatsDowngrade: [`${seatsRemoved} seat(s) will be removed from your plan on ${nextBillingCycleDate}. If the number of users exceed the new limit, user access will be removed. Users are removed starting with the most recent additions, until your account is not over the limit.`]
	}

	return SUBSCRIPTION_MODAL_MESSAGES[updateType]
}

const determineChangeType = (oldSeats: number, updatedSeats: number, currentPeriod: Period, nextPeriod?: Period): SubscriptionUpdateType | undefined => {
	const isSeatsUpgrade = updatedSeats > oldSeats
	const isSeatsDowngrade = updatedSeats < oldSeats
	const isPeriodChange = !!nextPeriod && currentPeriod !== nextPeriod

	if (isPeriodChange && isSeatsDowngrade) return 'periodChangeAndSeatsDowngrade'
	if (isPeriodChange && isSeatsUpgrade) return 'periodChangeAndSeatsUpgrade'
	if (isPeriodChange) return 'periodChange'
	if (isSeatsUpgrade) return 'seatsUpgrade'
	if (isSeatsDowngrade) return 'seatsDowngrade'
}
