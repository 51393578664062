import {useEffect} from 'react'
import {Alert, Box, Typography} from '@mui/material'
import {useUserContext} from '../../context/UserContext'
import {useUser} from '@clerk/clerk-react'
import {AnalyticsIdentify, GroupEvent, PageView} from '../../service/SegmentService'
import {useSearchContext} from '../../context/SearchContext'
import {useAiModelsContext} from '../../context/AIModelsContext'
import {useUserInfoContext} from '../../context/UserInfoContext'
import {useNavigate} from 'react-router-dom'
import {isModelEnabledForUser} from '../../helpers/AiModelHelper'
import {isAnyModelSelected} from '../../utils/aiModelUtils'
import {useUserGroupContext} from '../../context/UserGroupContext'
import {useUserBudgetContext} from '../../context/UserBudgetContext'
import {useUserOrganizationContext} from '../../context/UserOrganizationContext'
import {AiModelSelectorToggleGroup} from '../../components/aiModelSelector/AiModelSelectorToggleGroup'
import {NewChatInput} from './NewChatInput'
import BulbIcon from '@mui/icons-material/EmojiObjectsOutlined'
import './NewChat.scss'

export const NewChat = () => {
    const navigate = useNavigate()
    const {user} = useUser()
    const {aiModels, loading: loadingAiModels} = useAiModelsContext()
    const {userInfo, loading: loadingUserInfo} = useUserInfoContext()
    const {initialAnalyticTracked, setInitialAnalyticTracked} = useUserContext()
    const {userGroup} = useUserGroupContext()
    const {hasBudgetRemaining} = useUserBudgetContext()
    const {userOrganization} = useUserOrganizationContext()
    const {modelsSelected} = useSearchContext()

    const showChatInput = hasBudgetRemaining && isAnyModelSelected(modelsSelected)

    useEffect(() => {
        if (!initialAnalyticTracked) {
            setInitialAnalyticTracked(true)
            PageView('Narus')
            AnalyticsIdentify(user, userOrganization.organizationId)
            GroupEvent(user?.externalId ?? user?.id)
        }
    }, [user, initialAnalyticTracked, setInitialAnalyticTracked, aiModels, navigate, userInfo?.disabledAIModels, userOrganization.organizationId])

    /** When there are no models enabled by admin, redirect to "No Models Enabled Page" */
    useEffect(() => {
        if (!loadingAiModels && !loadingUserInfo) {
            const enabledModels = aiModels?.filter(model => model.isEnabled)
            if (!enabledModels?.length || !enabledModels.some(model => isModelEnabledForUser(model.id, aiModels, userInfo, userGroup))) {
                navigate('/noModelsEnabled')
            }
        }
    }, [aiModels, navigate, userInfo, loadingUserInfo, loadingAiModels, userGroup])

    return <Box className='NewChat_Container'>
		<Box className='NewChat_Content'>
			<Typography className='NewChat_Title'>
                Hello {user?.firstName}
            </Typography>
            <Alert className='NewChat_TitleAlert' severity='info' icon={<BulbIcon/>}>
                Select one or multiple models
            </Alert>
			<AiModelSelectorToggleGroup/>
		</Box>
        {showChatInput ? <NewChatInput /> : <></>}
    </Box>
}
