import {Box, Button, Drawer, Grid, TextField, Typography} from '@mui/material'
import React, {useState} from 'react'
import {TrackActionEvent} from '../../../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import {useToggleDrawerContext} from '../../../../context/ToggleDrawerContext'
import {useWarningsContext} from '../../../../context/WarningsContext'
import {TopicWarning, WarningElement} from '../../../../types/Warning'
import {EditTopicWarning} from './EditTopicWarning'
import {WarningItem} from './WarningItem'
import './Warnings.scss'
import {useFeedbackContext} from '../../../../context/FeedbackContext'
import {SelectWarningLevel} from './SelectWarningLevel'

export const Topics = () => {

	const {user} = useUser()
	const {showFeedback} = useFeedbackContext()
	const {topicWarnings, createTopicWarning, removeTopicWarning} = useWarningsContext()
	const {displayDrawerEditTopicWarning, toggleDrawer} = useToggleDrawerContext()

	const [addingNewTopicWarning, setAddingNewTopicWarning] = useState<boolean>(false)
	const [topicValidError, setTopicValidError] = useState<boolean>(false)
	const [newTopic, setNewTopic] = useState<string>('')
	const [warningLevel, setWarningLevel] = useState<string>('medium')
	const [topicWarningToEdit, setTopicWarningToEdit] = useState<TopicWarning | undefined>()
	const [validationError, setValidationError] = useState<string>('')

	const handleCancelSaveTopicWarning = () => {
		setAddingNewTopicWarning(false)
		setValidationError('')
		setTopicValidError(false)
	}

	const handleDeleteTopicWarningClicked = async (warningByTopic: WarningElement) => {
		const error = await removeTopicWarning(warningByTopic as TopicWarning)
		if (!error) {
			showFeedback('Topic deleted', '', 'success', 5)
			return TrackActionEvent('Warnings', user?.externalId ?? user?.id, {action: 'remove', type: 'topic'})
		}
		showFeedback('Topic couldn\'t be deleted', 'Please, try it again', 'error', 5)
	}

	const handleSaveTopicWarning = async () => {
		if (!newTopic) {
			setTopicValidError(true)
			setValidationError('The topic is mandatory')
			return
		}
		if (topicWarnings.find(topicWarning => topicWarning.topic === newTopic)) {
			setTopicValidError(true)
			setValidationError('That topic already exists. Try adding a different one')
			return
		}
		setTopicValidError(false)
		const error = await createTopicWarning(newTopic, warningLevel)
		if (error) {
			setTopicValidError(true)
			setValidationError(error)
			return
		}

		TrackActionEvent('Warnings', user?.externalId ?? user?.id, {action: 'add', type: 'topic'})
		setNewTopic('')
		setWarningLevel('medium')
		setAddingNewTopicWarning(false)
		showFeedback('Topic saved', '', 'success', 5)
	}

	const handleEditClicked = (event: React.MouseEvent<HTMLButtonElement>, topicWarning: WarningElement) => {
		setTopicWarningToEdit(topicWarning as TopicWarning)
		return toggleDrawer(true, 'EDIT_TOPIC_WARNING_DRAWER')(event)
	}

	return <>
		<Grid container className='settingContainer'>
			<Box className='sectionContainer'>
				<Grid item xs={12}>
					<Typography className='warningSubtitle'>Topics</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography className='warningDescription'>Topics are a good way to generate warnings about a general subject without needing to generate a large list of words.</Typography>
				</Grid>
				{topicWarnings.length ? <Grid className='warningNameContainer'>
					{topicWarnings
						.map((topicWarning, index) =>
							<WarningItem warning={topicWarning} key={`warning-topic${index}`} handleDeleteClicked={handleDeleteTopicWarningClicked}
							             handleEditClicked={handleEditClicked} deleteEventName='topicWarningDeletion'/>)
					}
				</Grid> : <></>}
			</Box>
			{addingNewTopicWarning ? <Grid container className='settingContainer' >
					<Grid item xs={12}>
						<Typography className='warningSubtitle'>New topic</Typography>
					</Grid>
					<Grid item xs={6} className='warningFieldContainer'>
						<TextField variant='outlined' label='Topic' placeholder='Topic 1' fullWidth className='newWarningTextfield' error={topicValidError}
						           helperText={topicValidError ? validationError : ''}
						           onChange={(event) => setNewTopic(event.target.value)}/>
					</Grid>
					<Grid item xs={6}>
						<SelectWarningLevel setWarningLevel={setWarningLevel}/>
					</Grid>
					<Grid item xs={12}>
						<Button variant='contained' onClick={handleSaveTopicWarning} className='saveButton'>Add topic</Button>
						<Button variant='outlined' onClick={handleCancelSaveTopicWarning} className='cancelButton'>Cancel</Button>
					</Grid>
				</Grid> :
				<Grid container className='settingContainer'>
					<Grid item xs={12}>
						<Button onClick={() => setAddingNewTopicWarning(true)} className='addWarningButton'>+ Add topic</Button>
					</Grid>
				</Grid>
			}
		</Grid>
		<Drawer anchor='right' open={displayDrawerEditTopicWarning} onClose={toggleDrawer(false, 'EDIT_TOPIC_WARNING_DRAWER')}>
			{topicWarningToEdit ? <EditTopicWarning topicWarningToEdit={topicWarningToEdit}/> : <></>}
		</Drawer>
	</>
}