import {FC, PropsWithChildren} from 'react'
import {useLocation} from 'react-router-dom'
import {Grid} from '@mui/material'
import {FeedbackMessage} from '../feedbackMessage/FeedbackMessage'
import {MobileBanner} from '../mobileBanner/MobileBanner'
import '../../App.scss'

type Props = {
	hideBanners?: true
}

const getClassNameGridSuffix = (pathname: string): string => {
	if (pathname.startsWith('/admin')) {
		return 'admin'
	} else if (pathname.startsWith('/create-account')) {
		return 'account-creation'
	} else if (pathname.startsWith('/pricing')) {
		return 'pricing'
	} else if (pathname === '/') {
		return 'home'
	} else if (pathname.startsWith('/aiChat')) {
		return 'ai-chat'
	} else if (pathname.startsWith('/prompt')) {
		return 'prompt'
	} else {
		return ''
	}
}
export const AppGridWrapper: FC<PropsWithChildren<Props>> = ({children, hideBanners}) => {
	const location = useLocation()

	const classnameGridSuffix = getClassNameGridSuffix(location.pathname)

    return <Grid container spacing={2} className={`App ${classnameGridSuffix}`}>
        { children }
		{ !hideBanners ? <>
            <FeedbackMessage/>
            <MobileBanner/>
		</> : <></>}
    </Grid>
}