import {ClerkProvider} from '@clerk/clerk-react'
import {ErrorBoundary} from 'react-error-boundary'
import {ErrorSection} from './error/ErrorSection'
import {Outlet} from 'react-router'
import {ThemeProvider} from '@mui/material'
import {CUSTOM_THEME} from './themes/CustomThemes'
import {FeedbackContextProvider} from './context/FeedbackContext'
import {useSegment} from './hooks/useSegment'
import {EmailValidator} from './components/emailValidator/EmailValidator'

export const AppWrapper = () => {
    if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
        throw new Error('Missing Publishable Key')
    }
    const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY

    useSegment()

    return (
        <ThemeProvider theme={CUSTOM_THEME}>
            <ClerkProvider publishableKey={clerkPubKey} signInFallbackRedirectUrl={process.env.REACT_APP_FRONTEND_URL} signInForceRedirectUrl={process.env.REACT_APP_FRONTEND_URL}
                signInUrl={process.env.REACT_APP_CLERK_SIGN_IN_URL} signUpUrl={process.env.REACT_APP_CLERK_SIGN_UP_URL}>
                <ErrorBoundary FallbackComponent={ErrorSection}>
                    <FeedbackContextProvider>
                        <EmailValidator>
                            <Outlet/>
                        </EmailValidator>
                    </FeedbackContextProvider>
                </ErrorBoundary>
            </ClerkProvider>
        </ThemeProvider>
    )
}
