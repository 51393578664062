import {createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react'
import {useUserContext} from './UserContext'
import {FILE_UPLOAD_ERROR_MESSAGE, FileId, FileMetadata, FileUpload} from '../types/File'
import {checkFilesLoadStatus, deleteFile, getUploadFileURL, putFile} from '../service/persistenceService'
import {useUser} from '@clerk/clerk-react'
import {
    areFileUploadsMatch,
    areFileUploadsMatchChanged,
    createFileUpload,
    getMetadata,
    getState,
    hasId,
    isFileSizeValid
} from '../utils/fileUtils'
import {put, remove} from '../utils/genericUtils'
import {AIModelID} from '../types/AiModel'

type FilesContextValue = {
    files: FileUpload[]
    /** Remove files from context without removing them from database
     * @param filterCondition If present, clear fileUploads based on filter condition. If not, clear all */
    clearFiles: (filterCondition?: (fileUpload: FileUpload) => boolean) => void
    removeFile: (fileUpload: FileUpload | FileMetadata) => void
    removeFiles: (files: Array<FileUpload | FileMetadata>) => void
    retryUpload: (fileUpload: FileUpload) => void
    uploadFile: (file: File, modelId?: AIModelID, conversationId?: string) => void
    isLoading: boolean
}

const DEFAULT_FILES_CONTEXT_VALUE: FilesContextValue = {
    files: [],
    clearFiles: () => {},
    removeFile: () => {},
    removeFiles: () => {},
    retryUpload: () => {},
    uploadFile: () => {},
    isLoading: false
}

const FilesContext = createContext<FilesContextValue>(DEFAULT_FILES_CONTEXT_VALUE)

export const useFilesContext = () => useContext(FilesContext)

export const FilesContextProvider: FC<PropsWithChildren> = ({children}) => {
    const {token} = useUserContext()
    const {user} = useUser()
    const [fileUploads, setFileUploads] = useState<FileUpload[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const userId = (user?.id ?? user?.externalId)!

    const pollIntervalRef = useRef<NodeJS.Timer>()

    const clearFiles = useCallback((filterCondition?: (fileUpload: FileUpload) => boolean) => {
        setFileUploads(prev => filterCondition ? prev.filter(filterCondition) : [])
        if (!filterCondition) {
            setIsLoading(false)
            clearInterval(pollIntervalRef.current)
            pollIntervalRef.current = undefined
        }
    }, [])

    const startFilesStatusCheckPolling = useCallback(async (fileIds: FileId[]) => {
        let retries = 0
        const maxRetries = 200
        const pollInterval = setInterval(async () => {
            try {
                retries++
                if (retries > maxRetries) {
                    setIsLoading(false)
                    clearFiles()
                    return
                }

                const {areFilesLoaded} = await checkFilesLoadStatus(token, fileIds)
                if (areFilesLoaded) {
                    clearInterval(pollInterval)
                    setIsLoading(false)
                }
            } catch (error) {
                clearInterval(pollInterval)
                setIsLoading(false)
            }
        }, 2000)
        pollIntervalRef.current = pollInterval
    }, [token, clearFiles])

    const upload = useCallback((fileUpload: FileUpload) => {
        const loadingFileUpload: FileUpload = { ...fileUpload, state: 'loading' }
        const {metadata: {conversationId, id: fileId}, file} = fileUpload
        setFileUploads(put(loadingFileUpload, areFileUploadsMatch, areFileUploadsMatchChanged))
        getUploadFileURL(token, fileId, conversationId)
            .then(({uploadURL}) => putFile(uploadURL, file))
            .then(() => {
                const completedFileUpload: FileUpload = { ...fileUpload, state: 'completed' }
                setFileUploads(put(completedFileUpload, areFileUploadsMatch, areFileUploadsMatchChanged))
            })
            .catch(() => {
                const failedFiledUpload: FileUpload = { ...fileUpload, state: 'error' }
                setFileUploads(put(failedFiledUpload, areFileUploadsMatch, areFileUploadsMatchChanged))
                setIsLoading(false)
            })
    }, [token])
    
    const uploadFile = useCallback((file: File, modelId?: AIModelID, conversationId?: string) => {
        const fileUpload: FileUpload = createFileUpload(file, modelId, userId, conversationId)
        if (!isFileSizeValid(file)) {
            setFileUploads(prev => [...prev, {
                ...fileUpload,
                state: 'error',
                errorMessage: FILE_UPLOAD_ERROR_MESSAGE.FILE_SIZE_ERROR
            }])
            return
        }

        setIsLoading(true)
        setFileUploads(put(fileUpload, areFileUploadsMatch, areFileUploadsMatchChanged))
        upload(fileUpload)
    }, [upload, userId])

    const retryUpload = useCallback((fileUpload: FileUpload) => {
        upload(fileUpload)
    }, [upload])

    const removeFile = useCallback((file: FileUpload | FileMetadata) => {
        const { id } = getMetadata(file)
        if (getState(file) === 'completed') {
            deleteFile(token, id)
        }
        setFileUploads(remove(hasId(id)))
    }, [token])

    const removeFiles = useCallback((files: Array<FileUpload | FileMetadata>) => {
        files.forEach(removeFile)
    }, [removeFile])

    useEffect(() => {
        const validFileUploads = fileUploads.filter(fileUpload => fileUpload.state !== 'error')
        if (validFileUploads.length && validFileUploads.every(fileUpload => fileUpload.state === 'completed')) {
            const fileIds = validFileUploads.map(file => file.metadata.id)
            startFilesStatusCheckPolling(fileIds)
        }
    }, [fileUploads, startFilesStatusCheckPolling])

    const value: FilesContextValue = useMemo(() => ({
        files: fileUploads,
        clearFiles,
        uploadFile,
        retryUpload,
        removeFile,
        removeFiles,
        isLoading
    }), [fileUploads, clearFiles, removeFile, removeFiles, retryUpload, uploadFile, isLoading])

    return (
        <FilesContext.Provider value={value}>
            {children}
        </FilesContext.Provider>
    )
}