import {ChangeEvent, FC, useCallback} from 'react'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import {IconButton, IconButtonOwnProps} from '@mui/material'
import {useFilesContext} from '../../context/FilesContext'
import {AIModelID} from '../../types/AiModel'
import './FileUploadIconButton.scss'

type Props = {
    className?: string
    size?: IconButtonOwnProps['size']
    modelId?: AIModelID
    conversationId?: string
    disabled?: boolean
}

export const FileUploadIconButton: FC<Props> = ({
    className,
    size,
    modelId,
    conversationId,
    disabled
}) => {
    const { uploadFile } = useFilesContext()

    const buttonClassName = ['FileUploadIconButton', className].filter(item => item).join(' ')

	const handleInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files
		if (files) {
            Array
                .from(files)
                .forEach(file => uploadFile(file, modelId, conversationId))
		}
		event.target.value = ''
	}, [modelId, conversationId, uploadFile])

    return (
        <IconButton className={buttonClassName} size={size || 'medium'} component='label' disabled={disabled}>
            <AttachFileIcon/>
			<input type='file' multiple hidden onChange={handleInputChange}/>
        </IconButton>
    )
}