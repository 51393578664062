import { useEffect, useState } from 'react'
import { clamp } from '../helpers/NumberHelpers'

const REFRESH_RATE = 60
const TIMEOUT_MILLIS = 1000 / REFRESH_RATE // 16,667
const TIMEOUT_SECONDS = TIMEOUT_MILLIS / 1000
const CHARACTERS_THRESHOLD = 1

/**
 * Custom hook that, passed a string, returns a partial chain of it that progressively completes.
 * @param value the target string value that should be displayed progressively
 * @param speed a speed factor value that will indicate the amount of "remaining value" that should be displayed per second.
 * ie: the default value of 0.8 means that every second, and 80% of remaining value is progressed    
 */
export const useProgressiveString = (value: string, speed: number = 0.8) => {
    const [currentValue, setCurrentValue] = useState('')
    const [targetValue, setTargetValue] = useState(value)

    useEffect(() => {
        setTargetValue(value)
    }, [value])

    useEffect(() => {
        let progressionTimeout
        if (currentValue !== targetValue) {
            progressionTimeout = setTimeout(() => {
                const remainingValue = targetValue.startsWith(currentValue)
                    ? targetValue.substring(currentValue.length)
                    : targetValue
                const startValue = targetValue.startsWith(currentValue)
                    ? currentValue
                    : ''
                const addedValue = remainingValue.length > CHARACTERS_THRESHOLD
                    ? remainingValue.substring(0, clamp(Math.floor(TIMEOUT_SECONDS * speed * remainingValue.length), 1, remainingValue.length))
                    : remainingValue
                setCurrentValue(`${startValue}${addedValue}`)
            }, TIMEOUT_MILLIS)
        }

        return () => {
            if (progressionTimeout) {
                clearTimeout(progressionTimeout)
            }
        }
    }, [currentValue, targetValue, speed])


    return {
        value: currentValue
    }
}