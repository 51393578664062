import {Box, Button} from '@mui/material'
import {useSearchContext} from '../../context/SearchContext'
import {useAiModelsContext} from '../../context/AIModelsContext'
import {useUserInfoContext} from '../../context/UserInfoContext'
import {useUserGroupContext} from '../../context/UserGroupContext'
import {isModelEnabledForUser} from '../../helpers/AiModelHelper'
import {AiModelIcon} from '../icons/AiModelIcon'
import {AIModelID} from '../../types/AiModel'
import './AiModelSelectorToggleGroup.scss'

export const AiModelSelectorToggleGroup = () => {
    const {aiModels} = useAiModelsContext()
    const {userInfo} = useUserInfoContext()
    const {userGroup} = useUserGroupContext()
    const {modelsSelected, setModelsSelected} = useSearchContext()

    const displayedModels = aiModels
        //.slice(0, 20) // Comment Filter for now. In following task, reapply filter
        .filter(({id}) => id !== AIModelID.AMAZON_Q && isModelEnabledForUser(id, aiModels, userInfo, userGroup))

    const handleAiModelToggle = (modelId: AIModelID) => {
        setModelsSelected(prevState => ({...prevState, [modelId]: !prevState[modelId]}))
    }

    return (
        <Box className='AiModelSelector_Container'>
            {displayedModels.map(({id, name}) => (
                <Button key={id} 
                    className={`AiModelSelector_Button${modelsSelected[id] ? ' Mui-selected' : ''}`}
                    startIcon={<AiModelIcon modelId={id}/>}
                    onClick={() => handleAiModelToggle(id)}>
                        {name}
                </Button>
            ))}
        </Box>
    )
}