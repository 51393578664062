import {FC} from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Chip,
    Grid,
    Typography
} from '@mui/material'
import {ProgressBar} from '@tremor/react'
import './SubscriptionSettings.scss'
import {TrackActionEvent} from '../../../service/SegmentService'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InfoIcon from '@mui/icons-material/Info'
import CheckIcon from '@mui/icons-material/Check'
import {useUser} from '@clerk/clerk-react'
import {getPeriodByPriceId, getPlanByPriceId} from '../../../utils/licenseUtils'
import {PRICES_INFO_MAP} from '../../../constants/StripeConstants'
import {usePaymentContext} from '../../../context/PaymentContext'
import {useUsersAccessContext} from '../../../context/UsersAccessContext'
import {clamp} from '../../../helpers/NumberHelpers'
import {Subscription} from '../../../types/Stripe'
import {isAwsSubscription, isTrialSubscription} from '../../../utils/subscriptionUtils'
import {AWSSubscriptionSettings} from './AWSSubscriptionSettings'
import {SubscriptionDetailsSettings} from './SubscriptionDetailsSettings'
import {useNavigate} from 'react-router-dom'

type Props = {
    subscription: Subscription
}

export const SubscriptionSettings: FC<Props> = ({subscription}) => {
    const {user} = useUser()
    const navigate = useNavigate()

    const {usersInfo} = useUsersAccessContext()
    const {setOpenChangePlanDialog} = usePaymentContext()

    const plan = getPlanByPriceId(subscription.plan.id)
    const period = getPeriodByPriceId(subscription.plan.id)

    const benefits = plan ? PRICES_INFO_MAP[plan].benefits : []
    const isCurrentlySubscriptionCancelled = subscription.cancellation_details?.reason === 'cancellation_requested'
    const activeSeatsProgress = clamp(usersInfo.length / subscription.quantity * 100, 0, 100)
    const isAWSSubscription = isAwsSubscription(subscription)

    const onChangeCurrentPlanClick = () => {
        TrackActionEvent('Organization settings', user?.externalId ?? user?.id, {
            action: 'changePlanModalOpen',
        })
        if (isTrialSubscription(subscription)) {
            navigate('/pricing')
        } else {
            setOpenChangePlanDialog(true)
        }
    }

    return <Grid item xs={10} className='submenuContent'>
        <Box className='licenseTitleContainer'>
            <Box className='licenseTitleWrapper'>
                <Typography className='licenseName'>{plan} Plan</Typography>
                {plan !== 'trial' && <Chip label={period} variant='outlined' className='periodChip'/>}
            </Box>
            {!isCurrentlySubscriptionCancelled && !isAWSSubscription &&
                <Button variant='outlined' className='changePlanButton' onClick={onChangeCurrentPlanClick}>Change plan</Button>}
        </Box>

        <Typography className='activeSeatsProgressBarText'>{usersInfo.length}/{subscription.quantity} active
            seats</Typography>
        <ProgressBar className='activeSeatsProgressBar' value={activeSeatsProgress}/>

        <Accordion className='benefitsAccordion'>
            <AccordionSummary expandIcon={<ExpandMoreIcon className='expandMoreIcon'/>}>
                <InfoIcon className='infoIcon'/> <Typography className='benefitsTitle'>Plan features</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container className='benefitContainer'>
                    {benefits.map((benefit, index) => <Grid item xs={4} md={3} className='benefitItem' key={`benefit${index}`}>
                        <Typography> <CheckIcon className='checkIcon'/>{benefit}</Typography>
                    </Grid>)}
                </Grid>
            </AccordionDetails>
        </Accordion>
        {isAWSSubscription ?
            <AWSSubscriptionSettings/> :
            <SubscriptionDetailsSettings subscription={subscription}/>
        }
    </Grid>
}