import {Box, Button, Drawer, Grid, Link, TextField, Typography} from '@mui/material'
import {emailMatchesDomain, isEmailDomain, isGenericDomainEmail} from '../../../../utils/accountCreationHelpers'
import {AccountDomain} from './AccountDomain'
import React, {useState} from 'react'
import {TrackActionEvent} from '../../../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import {useOrganizationContext} from '../../../../context/OrganizationContext'
import {EditOrganizationDomain} from './EditOrganizationDomain'
import {useToggleDrawerContext} from '../../../../context/ToggleDrawerContext'
import {ADD_USER_DOMAINS_URL} from '../../../../constants/Links'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

export const UserAccess = () => {

	const {user} = useUser()
	const {organization, editOrganization} = useOrganizationContext()
	const {displayDrawerEditOrganizationDomain, toggleDrawer} = useToggleDrawerContext()

	const [domainValidError, setDomainValidError] = useState<boolean>(false)
	const [newDomain, setNewDomain] = useState<string>('')
	const [domainToEdit, setDomainToEdit] = useState<string>('')
	const [validationError, setValidationError] = useState<string>('')


	const handleDeleteDomainClicked = async (domain: string) => {
		if (deleteDisabled || (user?.emailAddresses.length && emailMatchesDomain(user?.emailAddresses[0].emailAddress)(domain))) return
		const error = await editOrganization(organization?.name ?? '', organization?.allowedDomains?.filter(allowedDomain => allowedDomain !== domain) ?? [])
		if (!error) TrackActionEvent('Organization settings', user?.externalId ?? user?.id, {
			action: 'removeDomain',
			organization_domains_count: organization?.allowedDomains?.length ?? 0
		})
	}

	const handleSaveDomain = async () => {
		const newDomains = newDomain.split(',').map(domain => domain.trim())
		if (newDomains.some(domain => !isEmailDomain(domain))) {
			setDomainValidError(true)
			setValidationError('Admin email has to follow the example @acme.com')
			return
		}
		if (newDomains.some(domain => isGenericDomainEmail(domain))) {
			setDomainValidError(true)
			setValidationError('Generic domains are not accepted')
			return
		}
		if (organization?.allowedDomains.find(allowedDomain => newDomains.includes(allowedDomain))) {
			setDomainValidError(true)
			setValidationError('That domain already exists. Try adding a different one')
			return
		}
		setDomainValidError(false)
		const allowedDomains = [...(organization?.allowedDomains ?? []), ...newDomains]
		const error = await editOrganization(organization?.name ?? '', allowedDomains)
		if (error) {
			setDomainValidError(true)
			setValidationError(error)
			return
		}

		TrackActionEvent('Organization settings', user?.externalId ?? user?.id, {action: 'addDomain', organization_domains_count: allowedDomains.length})
		setNewDomain('')
	}

	const handleEditClicked = (event: React.MouseEvent<HTMLButtonElement>, domain: string) => {
		setDomainToEdit(domain)
		return toggleDrawer(true, 'EDIT_ORGANIZATION_DOMAIN_DRAWER')(event)
	}

	const handleLearnMoreClick = () => {
		TrackActionEvent('Organization settings', user?.externalId ?? user?.id, {action: 'learnMoreClicked'})

	}

	const deleteDisabled = organization?.allowedDomains?.length !== undefined && organization?.allowedDomains?.length <= 1

	return <>
		<Grid container className='settingContainer'>
			<Grid item xs={12}>
				<Typography variant='h4' className='sectionTitle'>Domains</Typography>
			</Grid>
			<Grid item xs={12}>
				<Typography className='body'>Optionally add email domains (eg @kolekti.com) to easily control access to your workspace. Users who sign up for a Narus account
					using an email address with the specified domain(s) are automatically granted access to your workspace. <Link href={ADD_USER_DOMAINS_URL} target='_blank'
					                                                                                                              onClick={handleLearnMoreClick}>Learn how domains
						work.</Link></Typography>
			</Grid>
			<Grid item xs={12} className='addContainer'>
				<Box>
					<TextField variant='outlined' label='Domain' placeholder='Write one or more emails Eg. @yourcompany.com' fullWidth className='newDomainTextfield'
					           error={domainValidError}
					           helperText={domainValidError ? validationError : ''} size='small'
					           value={newDomain}
					           onChange={(event) => setNewDomain(event.target.value)}/>
				</Box>
				<Box>
					<Button variant='outlined' onClick={handleSaveDomain} className='saveButton'>Add domain</Button>
				</Box>
			</Grid>
			<Grid item xs={12} className='helperContainer'>
				<Box>
					<InfoOutlinedIcon/>
				</Box>
				<Box>
					<Typography variant='subtitle1' className='helperTextContainer'>To add multiple domains, list domains separated with a comma.</Typography>
				</Box>
			</Grid>
			<Grid className='domainNameContainer' xs={12}>
				{organization?.allowedDomains
					.filter(isEmailDomain)
					.map((allowedDomain, index) =>
						<AccountDomain domain={allowedDomain} key={`allowed-domain${index}`} deleteDisabled={deleteDisabled} isEditAllowed={true} showConfirmDelete={true}
						               handleDeleteClicked={handleDeleteDomainClicked} handleEditClicked={handleEditClicked} deleteEventName='domainDeletion'/>)
				}
			</Grid>
		</Grid>
		<Drawer anchor='right' open={displayDrawerEditOrganizationDomain} onClose={toggleDrawer(false, 'EDIT_ORGANIZATION_DOMAIN_DRAWER')}>
			{domainToEdit ? <EditOrganizationDomain domain={domainToEdit}/> : <></>}
		</Drawer>
	</>
}