import {useCallback, useEffect} from 'react'
import {Subscription} from '../../types/Stripe'
import {usePaymentContext} from '../../context/PaymentContext'
import {DialogParams, useAppNavigationContext} from '../../context/AppNavigationContext'
import {Location} from 'react-router-dom'
import {getNextPeriodInfo, getPeriodByPriceId} from '../../utils/licenseUtils'
import {useStripeSubscriptionsContext} from '../../context/StripeSubscriptionsContext'

export const useSubscriptionSettingsNavigation = (subscription: Subscription, saveChanges: () => void) => {
    const {
        addNavigationDialog,
        removeNavigationDialog,
        addBeforeNavigationCallback,
        removeBeforeNavigationCallback,
        addUnhandledNavigationCallback,
        removeUnhandledNavigationCallback
    } = useAppNavigationContext()
    const {
        seats,
        setSeats,
        periodSelected,
        setPeriodSelected
    } = usePaymentContext()
    const {schedules} = useStripeSubscriptionsContext()

    const nextPeriod = getNextPeriodInfo(subscription, schedules)
    const currentPeriod = getPeriodByPriceId(subscription.plan.id)
    const seatsChanged = subscription.quantity !== seats
    const periodChanged =  !!periodSelected && (nextPeriod.period !== periodSelected || currentPeriod !== periodSelected)
    const showDialogOnNavigate = seatsChanged || periodChanged

    // Function to restore context variables to its original value
    const cleanup = useCallback(() => {
        setSeats(subscription.quantity)
        setPeriodSelected(undefined)
    }, [subscription, setSeats, setPeriodSelected])

    // Determines whether the user can freely navigate out of the current page or not
    useEffect(() => {
        const dialogParams: DialogParams | undefined = showDialogOnNavigate
            ? {
                title: 'There are unsaved changes in the Current subscription section',
                text: 'Changes will not be applied if you do not save them.',
                primaryText: 'Save changes',
                secondaryText: 'Continue without saving',
                invertedAction: true,
                onPrimary: saveChanges,
                onSecondary: () => {},
                onClose: () => {}
            } : undefined

        if (dialogParams) {
            addNavigationDialog(dialogParams)
        }

        return () => {
            if (dialogParams) {
                removeNavigationDialog(dialogParams)
            }
        }
    }, [showDialogOnNavigate, saveChanges, cleanup, addNavigationDialog, removeNavigationDialog])

    // Ensure app navigation does cleanup on exit
	useEffect(() => {
		addBeforeNavigationCallback(cleanup)

		return () => {
			removeBeforeNavigationCallback(cleanup)
		}
	}, [cleanup, addBeforeNavigationCallback, removeBeforeNavigationCallback])

	// Ensure unhandled navigation (browser back and forward) does hard cleanup on exit
	useEffect(() => {
		const callback = (from: Location, to: Location) => {
			if (from.pathname.startsWith('/admin/configuration') && !to.pathname.startsWith('/admin/configuration')) {
				cleanup()
			}
		}
		addUnhandledNavigationCallback(callback)

		return () => {
			removeUnhandledNavigationCallback(callback)
		}
	}, [cleanup, addUnhandledNavigationCallback, removeUnhandledNavigationCallback])
}