import {ChangeEvent, useCallback, useEffect, useState} from 'react'
import {Badge, Box, Button, IconButton, TextField, Tooltip} from '@mui/material'
import {TrackActionEvent} from '../../service/SegmentService'
import {useSearchContext} from '../../context/SearchContext'
import {useUserInfoContext} from '../../context/UserInfoContext'
import {OptimizationSettingsDialog} from '../../components/aiAdvancedSettings/OptimizationSettingsDialog'
import {getSelectedModelIds, isAnyModelSelected} from '../../utils/aiModelUtils'
import {PromptChatDetail} from '../../types/PromptChatDetail'
import {usePromptCreationContext} from '../../context/PromptCreationContext'
import {useDebouncedCallback} from 'use-debounce'
import {FileUploadIconButton} from '../../components/fileUpload/FileUploadIconButton'
import {useFilesContext} from '../../context/FilesContext'
import {getPropValue, hasProp, not} from '../../utils/genericUtils'
import {getUnbindedUploadedFiles, isBinded} from '../../utils/fileUtils'
import {FileUploadChips} from '../../components/fileUpload/FileUploadChips'
import {useUser} from '@clerk/clerk-react'
import {useNavigate} from 'react-router-dom'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import Spinner from '../../components/spinner/Spinner'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import {useAbortController} from '../../hooks/useAbortController'
import {useAppNavigationContext} from '../../context/AppNavigationContext'
import './NewChatInput.scss'

export const NewChatInput = () => {
    const navigate = useNavigate()
    const {user} = useUser()
    const {userInfo, updateUserModels} = useUserInfoContext()
    const {isLoading, files, clearFiles} = useFilesContext()
    const {
        audience, tone, format, language, modelId, userPrompt, isOptimizedPrompt, setModelId,
        setValueHandler, setModelIds, setUserPrompt, setPromptChats, clearPromptFields
    } = usePromptCreationContext()
	const {
		addBeforeNavigationCallback,
		removeBeforeNavigationCallback,
	} = useAppNavigationContext()

    const {
        setSearchLoading,
        setSearchText,
        modelsSelected,
        isDefaultSearch,
        searchUserPrompts,
        clearSearch
    } = useSearchContext()

	const {abortControllerRefAbort} = useAbortController()

    const [showOptimizePromptSettings, setShowOptimizePromptSettings] = useState(false)

    const unboundedFiles = files.filter(not(isBinded))
    const fileUploadsCompleted = unboundedFiles.every(hasProp('state', 'completed'))
    const isSendPromptDisabled = !userPrompt.trim() || !isAnyModelSelected(modelsSelected) || !fileUploadsCompleted
    const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
        const inputText = event.target.value
        setUserPrompt(inputText)
        setSearchText(inputText)
        setSearchLoading(inputText.length > 0)
    }

    const handleSend = async () => {
        const createPromptAnalyticsInfo = {
            model: modelId,
            action: 'done'
        }

        if (tone) createPromptAnalyticsInfo['tone'] = tone
        if (audience) createPromptAnalyticsInfo['audience'] = audience
        if (format) createPromptAnalyticsInfo['format'] = format
        TrackActionEvent('Create prompt', user?.externalId ?? user?.id, createPromptAnalyticsInfo)

        const models = getSelectedModelIds(modelsSelected)
        setValueHandler(setModelIds, models, 'modelIds')
        setValueHandler(setModelId, models[0], 'modelId')

        if (userInfo) updateUserModels(models)

        setPromptChats(models.map(modelId => ({
            modelId,
            messages: !isOptimizedPrompt() ? [{
                sender: 'user',
                text: userPrompt,
                optimized: false,
                files: getUnbindedUploadedFiles(files).map(getPropValue('metadata'))
            }] : []
        })) as PromptChatDetail[])

        clearSearch()
        if (!isOptimizedPrompt()) setUserPrompt('')
        navigate(isOptimizedPrompt() ? '/aiChat/optimized' : '/aiChat')
    }

    const handleSearch = useDebouncedCallback(async (event) => {
        if (isDefaultSearch) {
            setSearchLoading(false)
            clearSearch()
        } else {
            searchUserPrompts(true)
            TrackActionEvent('Search', user?.externalId ?? user?.id, {action: 'search'})
        }
    }, 1000)

    const handleTextKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey && !isLoading && !isSendPromptDisabled) {
            event.preventDefault()
            handleSend()
        } else {
            handleSearch(event)
        }
    }

    const handleOptimizationSettingsDialogClosed = () => {
        setShowOptimizePromptSettings(false)
    }

	const cleanup = useCallback(() => {
		abortControllerRefAbort()
		clearPromptFields()
		clearFiles()
	}, [abortControllerRefAbort, clearPromptFields, clearFiles])

	// Ensure app navigation does cleanup on exit
	useEffect(() => {
		addBeforeNavigationCallback(cleanup)

		return () => {
			removeBeforeNavigationCallback(cleanup)
		}
	}, [cleanup, addBeforeNavigationCallback, removeBeforeNavigationCallback])

    return (
        <Box className='NewChatInput_Container'>
            { unboundedFiles.length ? 
                <Box className='NewChatInput_FilesContainer'>
                    <FileUploadChips fileUploads={unboundedFiles}/>
                </Box> : <></> }
            <TextField
                className='NewChatInput_TextField'
                fullWidth
                multiline
                rows={3}
                placeholder='Write your prompt...'
                variant='outlined'
                onChange={handleTextChange}
                onKeyDown={handleTextKeyDown}
            />
            <Box className='NewChatInput_Settings'>
                <Box className='NewChatInput_SettingsActions'>
                    <FileUploadIconButton className='NewChatInput_IconButton'/>
                    <IconButton className='NewChatInput_IconButton' onClick={() => setShowOptimizePromptSettings(true)}>
                        <Badge color='secondary' className='NewChatInput_IconButtonBadge' badgeContent={[format, tone, language].filter(optimizationParam => optimizationParam).length}>
                            <AutoFixHighIcon/>
                        </Badge>
                    </IconButton>
                </Box>
                { isLoading 
                    ? <Tooltip title='Uploading file...' placement='top'>
                        <Box><Spinner/></Box>
                    </Tooltip> 
                    : <Button className='NewChatInput_SendButton' variant='contained' size='small' startIcon={<ArrowForwardIcon/>} disabled={isSendPromptDisabled} onClick={handleSend}/>}
            </Box>
            <OptimizationSettingsDialog showSliders={showOptimizePromptSettings} onClose={handleOptimizationSettingsDialogClosed}/>
        </Box>
    )
}