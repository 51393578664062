import {useCallback, useEffect, useMemo, useState} from 'react'
import {searchPrompts} from '../service/persistenceService'
import {useUserContext} from '../context/UserContext'
import {Prompt, PromptVisibility} from '../types/Prompt'
import {useErrorBoundary} from 'react-error-boundary'
import {AIModelID, DEFAULT_SELECTED_MODELS} from '../types/AiModel'
import {SearchContextValue} from '../context/SearchContext'
import {splitTextAtByteLimit} from '../utils/textUtils'
import {useUserInfoContext} from '../context/UserInfoContext'
import {useAiModelsContext} from '../context/AIModelsContext'
import {isModelEnabledForUser} from '../helpers/AiModelHelper'
import {getSelectedModelIds, getAIModelIdMap} from '../utils/aiModelUtils'
import {hasId} from '../utils/genericUtils'
import {useUserGroupContext} from '../context/UserGroupContext'

export const useSearch = (): SearchContextValue => {

	const {token} = useUserContext()
	const {aiModels} = useAiModelsContext()
	const {userInfo} = useUserInfoContext()
    const {userGroup} = useUserGroupContext()

    const {showBoundary} = useErrorBoundary()

	const [searchLoading, setSearchLoading] = useState(false)
	const [searchText, setSearchText] = useState<string>('')
	const [archivedPromptsChecked, setArchivedPromptsChecked] = useState(false)
	const [currentPromptsChecked, setCurrentPromptsChecked] = useState(true)
	const [searchLabels, setSearchLabels] = useState<string[]>([])
	const [searchTeams, setSearchTeams] = useState<string[]>([])
	const [lastEvaluatedKeySearch, setLastEvaluatedKeySearch] = useState<string>()
	const [searchedPrompts, setSearchedPrompts] = useState<Prompt[]>([])
	const [modelsSelected, setModelsSelected] = useState<Record<AIModelID, boolean>>(DEFAULT_SELECTED_MODELS)
	const [isLongTextSearch, setIsLongTextSearch] = useState(false)
	const [searchAIModelIDs, setSearchAIModelIDs] = useState<AIModelID[]>([])
	const [searchVisibility, setSearchVisibility] = useState<PromptVisibility[]>(['public', 'private'])

	const clearSearch = useCallback(() => {
		setSearchText('')
		setSearchedPrompts([])
		setIsLongTextSearch(false)
		setSearchLabels([])
		setSearchAIModelIDs([])
		setSearchVisibility(['public', 'private'])
	}, [])

	const searchUserPrompts = useCallback((newSearch: boolean) => {
		clearSearch()
		setSearchLoading(true)
		const searchParams = {
			searchText,
			archived: archivedPromptsChecked,
			nonArchived: currentPromptsChecked,
			teams: searchTeams,
			labels: searchLabels,
			lastEvaluatedKey: newSearch ? '' : lastEvaluatedKeySearch
		}
		const textByteSize = new Blob([searchText]).size
		const byteLimit = 100
		if (textByteSize >= byteLimit) {
			const splitText = splitTextAtByteLimit(searchText, byteLimit)
			setSearchText(splitText)
			searchParams.searchText = splitText
			setIsLongTextSearch(true)
		}
		searchPrompts(searchParams, token).then(searchedPromptsResponse => {
			setSearchedPrompts(previousSearchPrompts => [...previousSearchPrompts, ...searchedPromptsResponse.prompts])
			setLastEvaluatedKeySearch(searchedPromptsResponse.lastEvaluatedKey)
		})
			.catch(showBoundary)
			.finally(() => setSearchLoading(false))
	}, [archivedPromptsChecked, currentPromptsChecked, lastEvaluatedKeySearch, searchTeams, searchLabels, searchText, token, showBoundary, clearSearch])

	const setDefaultSelectedModels = useCallback(() => {
		setModelsSelected(prevModels => {
			const userEnabledAIModels = aiModels.filter(({id}) => isModelEnabledForUser(id, aiModels, userInfo, userGroup))
			const userInfoSelectedModelIds = userInfo?.selectedAIModels ?? []
			const userInfoEnabledModelIds = userInfoSelectedModelIds.filter(id => userEnabledAIModels.some(hasId(id)))
			const prevSelectedModelIds = getSelectedModelIds(prevModels)
			const prevSelectedEnabledModelIds = prevSelectedModelIds.filter(id => userEnabledAIModels.some(hasId(id)))

			if (userInfoEnabledModelIds.length) {
				return {...prevModels, ...getAIModelIdMap(true)(userInfoEnabledModelIds)}
			} else if ((prevSelectedEnabledModelIds.length && !userInfoEnabledModelIds.length) || !userEnabledAIModels.length) {
				return prevModels
			} else {
				return {...prevModels, [userEnabledAIModels[0]?.id]: true}
			}
		})
	}, [aiModels, userGroup, userInfo])

	useEffect(() => {
		setDefaultSelectedModels()
	}, [setDefaultSelectedModels])

	useEffect(() => {
		setModelsSelected((prevState) => {
			const aiModelIds = new Set(aiModels.map(model => model.id))
			const updatedState = Object.fromEntries(
				Object.entries(prevState).map(([modelId, isSelected]) => [
					modelId,
					isSelected && aiModelIds.has(modelId as AIModelID) && aiModels.find(model => model.id === modelId)?.isEnabled
				])
			)

			return {...prevState, ...updatedState}
		})
	}, [aiModels, setModelsSelected])

	return useMemo(() => ({
		searchLoading, setSearchLoading,
		searchText, setSearchText,
		archivedPromptsChecked, setArchivedPromptsChecked,
		currentPromptsChecked, setCurrentPromptsChecked,
		searchLabels, setSearchLabels,
		searchTeams, setSearchTeams,
		modelsSelected, setModelsSelected,
		searchedPrompts, setSearchedPrompts,
		isLongTextSearch, setIsLongTextSearch,
		lastEvaluatedKeySearch,
		isDefaultSearch: searchText.length === 0 && searchLabels.length === 0,
		clearSearch,
		searchUserPrompts,
		setDefaultSelectedModels,
		searchAIModelIDs, setSearchAIModelIDs,
		searchVisibility, setSearchVisibility
	}), [
		searchLoading,
		searchText,
		archivedPromptsChecked,
		currentPromptsChecked,
		searchLabels,
		searchTeams,
		modelsSelected,
		searchedPrompts,
		isLongTextSearch,
		lastEvaluatedKeySearch,
		clearSearch,
		searchUserPrompts,
		setDefaultSelectedModels,
		searchAIModelIDs,
		searchVisibility
	])
}