import {Grid, Tab, Tabs, Typography} from '@mui/material'
import './GlobalSettings.scss'
import {useUser} from '@clerk/clerk-react'
import {Admins} from './Admins'
import {UserAccess} from './UserAccess'
import {CustomTabPanel} from '../../../homeTabs/HomeTabs'
import {OrganizationDetails} from './OrganizationDetails'
import {TrackActionEvent} from '../../../../service/SegmentService'
import {LicenseSettings} from './LicenseSettings'
import {useAppNavigationContext} from '../../../../context/AppNavigationContext'
import {InternalNavigationMap} from '../../../../types/AppNavigation'

export const GlobalSettings = () => {

	const {user} = useUser()

	const {internalNavigation, internalNavigate} = useAppNavigationContext()

	const selectedTab: InternalNavigationMap['globalSettings'] = internalNavigation['globalSettings'] ?? 0

	const onTabChange = (_, newValue: InternalNavigationMap['globalSettings']) => {
		const tabNames = ['workspace', 'licenseBilling']
		TrackActionEvent('Organization settings', user?.externalId ?? user?.id, {
			action: 'changeTab',
			tab: tabNames[newValue]
		})
		internalNavigate({globalSettings: newValue})
	}

	return <Grid container className='globalSettings'>
		<Grid item xs={12}>
			<Typography variant='h5' className='title'>Global settings</Typography>
			<Tabs value={selectedTab} className='settingsTabContainer'
			      onChange={onTabChange}>
				<Tab label='Workspace'/>
				<Tab label='Manage subscription'/>
			</Tabs>
			<CustomTabPanel value={selectedTab} index={0}>
				<OrganizationDetails/>
				<UserAccess/>
				<Admins/>
			</CustomTabPanel>
			<CustomTabPanel value={selectedTab} index={1}>
				<LicenseSettings/>
			</CustomTabPanel>
		</Grid>
	</Grid>
}