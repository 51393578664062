import React, {FC, useEffect, useRef, useState} from 'react'
import {
	codeBlockPlugin,
	CodeMirrorEditor,
	codeMirrorPlugin,
	headingsPlugin,
	imagePlugin,
	linkDialogPlugin,
	linkPlugin,
	listsPlugin,
	markdownShortcutPlugin,
	MDXEditor,
	MDXEditorMethods,
	quotePlugin,
	RealmPlugin,
	tablePlugin,
	thematicBreakPlugin
} from '@mdxeditor/editor'
import './MarkdownEditor.scss'
import {debouncedFormatCodeBlock, debouncedProcessLiElements} from '../../utils/htmlUtils'
import {usePromptCreationContext} from '../../context/PromptCreationContext'
import {useFeedbackContext} from '../../context/FeedbackContext'
import { useProgressiveString } from '../../hooks/useProgressiveString'

type Props = {
	onChangeHandler?: (value: string) => void
	aiText: string
	loadingText: string
	isLoading: boolean
	label: string
	placeholderValue?: string
	isUser?: boolean
	animated?: boolean
}

const mdPlugins: RealmPlugin[] = [
	headingsPlugin(),
	listsPlugin(),
	linkPlugin(),
	linkDialogPlugin(),
	quotePlugin(),
	imagePlugin(),
	tablePlugin(),
	thematicBreakPlugin(),
	codeBlockPlugin({
		defaultCodeBlockLanguage: 'js',
		codeBlockEditorDescriptors: [
			{
				priority: 100,
				match: () => true,
				Editor: CodeMirrorEditor
			}]
	}),
	codeMirrorPlugin({
		codeBlockLanguages: {js: 'JavaScript', css: 'CSS', html: 'HTML', ts: 'Typescript'}
	}),
	markdownShortcutPlugin()
]

export const MarkdownEditor: FC<Props> = ({
	aiText, 
	loadingText,
	isLoading,
	label,
	placeholderValue,
	isUser,
	animated,
	onChangeHandler, 
}) => {

	const markdownEditorRef = useRef<MDXEditorMethods | null>(null)

	const [loadingTextWithEllipsis, setLoadingTextWithEllipsis] = useState<string>(loadingText)

	const {aiOutputLoading} = usePromptCreationContext()
	const {showFeedback} = useFeedbackContext()

	const { value: animatedText } = useProgressiveString(aiText, 2)
	const displayedText = animated ? animatedText : aiText

	useEffect(() => {
		markdownEditorRef.current?.setMarkdown(isLoading && !displayedText ? loadingTextWithEllipsis : displayedText ?? '')
		const intervalID = setInterval(() => setLoadingTextWithEllipsis(previousText => previousText.endsWith('...') ? loadingText : (previousText + '.')), 600)
		if (!isLoading) clearInterval(intervalID)
		return () => clearInterval(intervalID)
	}, [displayedText, isLoading, loadingText, loadingTextWithEllipsis])

	useEffect(() => {
		if (!aiOutputLoading) {
			debouncedProcessLiElements()
			debouncedFormatCodeBlock(aiText,
				() => showFeedback('Success', 'Text copied to clipboard', 'success', 5),
				() => showFeedback('Error', 'The text couldn\'t be copied, please try again.', 'error'))
		}
	}, [aiOutputLoading, showFeedback, aiText])

	return <MDXEditor
		ref={markdownEditorRef}
		onChange={onChangeHandler}
		className={`markdownEditor ${isUser ? 'userMessageListNone' : ''}`}
		onError={error => console.error(error)}
		markdown={displayedText}
		readOnly={label === 'message'}
		placeholder={placeholderValue || ''}
		suppressHtmlProcessing={true}
		plugins={mdPlugins}
	/>
}