import {AsyncState} from './AsyncState'

export type FileId = string
export type FileMetadata = {
    id: FileId
    name: string
    timestamp: number
    modelId?: string
    ownerId?: string
    conversationId?: string
    messageId?: string
}

export type FileUpload = {
    file: File,
    state: AsyncState,
    metadata: FileMetadata,
    errorMessage?: FILE_UPLOAD_ERROR_MESSAGE
}

export enum FILE_UPLOAD_ERROR_MESSAGE {
    FILE_SIZE_ERROR = 'File size exceeds 200MB limit.'
}