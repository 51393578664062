export const AUDIO_EXTENSIONS: readonly string[] = [
    'aac',
    'aiff',
    'alac',
    'amr',
    'ape',
    'au',
    'flac',
    'm4a',
    'm4b',
    'mp3',
    'ogg',
    'oga',
    'opus',
    'wav',
    'wma',
    'wv',
    'ac3',
    'dts',
    'gsm',
    'mid',
    'midi',
    'mod',
    'ra',
    'rm',
    'vox',
    'aifc',
    'caf',
    'dsd',
    'lpcm',     
] as const

export const IMAGE_EXTENSIONS: readonly string[] = [
    'jpg',
    'jpeg',
    'png',
    'gif',
    'bmp',
    'tiff',
    'tif',
    'webp',
    'svg',
    'avif',
    'heic',
    'jfif',
    'ico',
    'psd',
    'raw',
    'cr2',
    'nef',
    'orf',
    'arw',
    'dng',
] as const

export const CODE_EXTENSIONS: readonly string[] = [
    'js',
    'ts',
    'jsx',
    'tsx',
    'mjs',
    'cjs',
    'html',
    'htm',
    'css',
    'scss',
    'sass',
    'less',
    'json',
    'xml',
    'java',
    'py',
    'cpp',
    'c',
    'h',
    'hpp',
    'cs',
    'go',
    'php',
    'rb',
    'swift',
    'kt',
    'rs',
    'lua',
    'sql',
    'pl',
    'sh',
    'ps1',
    'vb',
    'm',
    'mm',
    'md',
    'txt',
    'yml',
    'yaml',
    'toml',
    'ini',
    'properties',
    'npmignore',
    'gitignore',
    'gradle',
    'pom',
    'sln',
    'csproj',
    'vbproj',
]