import {createContext, Dispatch, ReactNode, SetStateAction, useContext} from 'react'
import {useStripeSubscriptions} from '../hooks/useStripeSubscriptions'
import {ChangePlanAction, Subscription, SubscriptionSchedule} from '../types/Stripe'
import Spinner from '../components/spinner/Spinner'

interface StripeSubscriptionsContextProviderProps {
    children: ReactNode
}

export interface StripeSubscriptionsContextValue {
    subscriptions: Subscription[]
    schedules: SubscriptionSchedule[]
    currentSubscription?: Subscription
    isActiveSubscription: boolean
    trialDaysLeft: number | undefined
    isTrial: boolean
    isPausedSubscription: boolean
    isCurrentSubscriptionGrowth: boolean
    isCurrentSubscriptionScale: boolean
    isLoadingSubscriptions: boolean
    cancelCurrentSubscription: (subscriptionId: string) => Promise<void>
    updateSubscriptionSeats: (seats: number) => Promise<void>
    updateSubscriptionPlan: (newPlanId: string, action: ChangePlanAction, nextPeriodSeats?: number, nextPeriodPlanId?: string) => Promise<void>
    subscriptionSchedule: SubscriptionSchedule | undefined
    setSubscriptionSchedule: Dispatch<SetStateAction<SubscriptionSchedule | undefined>>
}

const DEFAULT_SUBSCRIPTIONS_CONTEXT: StripeSubscriptionsContextValue = {
    subscriptions: [],
    schedules: [],
    isActiveSubscription: false,
    trialDaysLeft: undefined,
    isTrial: false,
    isPausedSubscription: false,
    isCurrentSubscriptionGrowth: false,
    isCurrentSubscriptionScale: false,
    isLoadingSubscriptions: false,
    cancelCurrentSubscription: () => Promise.resolve(),
    updateSubscriptionSeats: (seats: number) => Promise.resolve(),
    updateSubscriptionPlan: () => Promise.resolve(),
    subscriptionSchedule: undefined,
    setSubscriptionSchedule: () => {}
}

export const StripeSubscriptionsContext = createContext<StripeSubscriptionsContextValue>(DEFAULT_SUBSCRIPTIONS_CONTEXT)

export const useStripeSubscriptionsContext = () => useContext(StripeSubscriptionsContext)

export const StripeSubscriptionsContextProvider = ({children}: StripeSubscriptionsContextProviderProps) => {
    const value = useStripeSubscriptions()

    return value.isLoadingSubscriptions ? <Spinner/> : <StripeSubscriptionsContext.Provider value={value}>{children}</StripeSubscriptionsContext.Provider>
}