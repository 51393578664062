import {FC} from 'react'
import {Box, IconButton, Tooltip} from '@mui/material'
import RetryIcon from '@mui/icons-material/Replay'
import CopyIcon from '@mui/icons-material/ContentCopy'
import './HoverMenuOutput.scss'

type Props = {
	regeneratedOutputs?: string[]
    isLastMessage?: boolean
    isBot: boolean
    onRegenerateOutput?: () => void
    onCopyOutput: () => void
}

export const HoverMenuOutput: FC<Props> = ({
	regeneratedOutputs, 
	isLastMessage, 
	isBot,
	onRegenerateOutput, 
	onCopyOutput, 
}) => {

	return <Box className='HoverMenu_Container'>
		<Tooltip title='Copy' placement='top' className='HoverMenu_Tooltip'>
			<IconButton onClick={onCopyOutput} className='HoverMenu_IconButton'><CopyIcon/></IconButton>
		</Tooltip>
		{isLastMessage && isBot 
			? <Tooltip title='Regenerate' placement='top' className='HoverMenu_Tooltip'>
				<span>
					<IconButton className='HoverMenu_IconButton' 
						disabled={regeneratedOutputs ? regeneratedOutputs.length >= 4 : false}
						onClick={onRegenerateOutput}>
							<RetryIcon/>
					</IconButton>
                </span>
			</Tooltip> : <></>}
	</Box>
}