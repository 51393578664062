import {useUserContext} from '../context/UserContext'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {useFeedbackContext} from '../context/FeedbackContext'
import {cancelSubscription, getSubscriptions, updateSubscription} from '../service/persistenceService'
import {StripeSubscriptionsContextValue} from '../context/StripeSubscriptionsContext'
import {TrackActionEvent} from '../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import {ChangePlan, ChangePlanAction, Subscription, SubscriptionSchedule} from '../types/Stripe'
import {getDaysUntil} from '../utils/dateUtils'
import {
	getCurrentSubscription,
	isGrowthSubscription,
	isOrganizationNotPaidAfterTrial,
	isScaleSubscription,
	isTrialSubscription,
	isValidSubscription
} from '../utils/subscriptionUtils'
import {isSeatsUpgrade} from '../utils/licenseUtils'

export const useStripeSubscriptions = (): StripeSubscriptionsContextValue => {
	const {token} = useUserContext()
	const {showFeedback} = useFeedbackContext()
	const {user} = useUser()

	const [subscriptions, setSubscriptions] = useState<Subscription[]>([])
	const [schedules, setSchedules] = useState<SubscriptionSchedule[]>([])
	const [currentSubscription, setCurrentSubscription] = useState<Subscription>()
	const [isActiveSubscription, setIsActiveSubscription] = useState<boolean>(true)
	const [trialDaysLeft, setTrialDaysLeft] = useState<number>()
	const [isPausedSubscription, setIsPausedSubscription] = useState<boolean>(false)
	const [isLoadingSubscriptions, setIsLoadingSubscriptions] = useState<boolean>(true)
	const [subscriptionSchedule, setSubscriptionSchedule] = useState<SubscriptionSchedule>()

	const getOrganizationSubscriptions = useCallback(() => {
		setIsLoadingSubscriptions(true)
		getSubscriptions(token).then(({subscriptions, schedules }) => {
			setIsPausedSubscription(false)
			setSubscriptions(subscriptions)
			setSchedules(schedules)
			const currentSubscription = getCurrentSubscription(subscriptions)
			setCurrentSubscription(currentSubscription)
			if (currentSubscription) {
				const isValidCurrentSubscription = isValidSubscription(currentSubscription)
				setIsActiveSubscription(isValidCurrentSubscription)
				if (isTrialSubscription(currentSubscription)) setTrialDaysLeft(getDaysUntil(currentSubscription.trial_end!))
				if (!isValidCurrentSubscription) {
					if (isOrganizationNotPaidAfterTrial(currentSubscription)) setIsPausedSubscription(true)
				}
				TrackActionEvent('Subscription', user?.externalId ?? user?.id, {
					is_active: isValidCurrentSubscription,
					action: 'validate'
				})
			} else {
				setIsActiveSubscription(false)
			}
		}).catch(() => {
			showFeedback('Error', 'Something went wrong to load subscriptions', 'error')
			setSubscriptions([])
			setSchedules([])
		}).finally(() => {
			setIsLoadingSubscriptions(false)
		})
	}, [token, showFeedback, user?.id, user?.externalId])

	const cancelCurrentSubscription = useCallback((subscriptionId: string): Promise<void> => {
		return cancelSubscription(token, subscriptionId)
			.then(() => {
				getOrganizationSubscriptions()
				showFeedback('Success', 'The subscription has been successfully cancelled', 'success')
				TrackActionEvent('Organization settings', user?.externalId ?? user?.id, {
					action: 'cancelSubscription',
				})
			})
			.catch(() => {
				showFeedback('Error', 'Something went wrong to load subscriptions', 'error')
			})
	}, [showFeedback, token, user, getOrganizationSubscriptions])

	const updateSubscriptionSeats = useCallback(async (seats: number) => {
		if (currentSubscription) {
			const changePlanInfo: ChangePlan = {
				currentPlanId: currentSubscription.plan.id,
				newPlanId: currentSubscription.plan.id,
				action: isSeatsUpgrade(currentSubscription.quantity, seats) ? 'upgrade' : 'downgrade',
				seats
			}

			await updateSubscription(token, changePlanInfo).then((subscriptionScheduleResult) => {
				getOrganizationSubscriptions()
				setSubscriptionSchedule(subscriptionScheduleResult)
				showFeedback('Success', 'The subscription has been successfully updated', 'success')
			}).catch(() => {
				showFeedback('Error', 'Something went wrong to update your subscription. Please, try again', 'error')
			})
		}
	}, [token, currentSubscription, showFeedback, getOrganizationSubscriptions])

	const updateSubscriptionPlan = useCallback(async (newPlanId: string, action: ChangePlanAction, nextPeriodSeats: number | undefined, nextPeriodPlanId: string | undefined) => {
		if (currentSubscription) {
			const changePlanInfo: ChangePlan = {
				currentPlanId: currentSubscription.plan.id,
				newPlanId,
				action,
				seats: nextPeriodSeats ?? currentSubscription.quantity,
				nextPeriodPlanId: nextPeriodPlanId ?? undefined
			}

			await updateSubscription(token, changePlanInfo).then((subscriptionScheduleResult) => {
				getOrganizationSubscriptions()
				setSubscriptionSchedule(subscriptionScheduleResult)
				showFeedback('Success', 'The subscription has been successfully updated', 'success')
			}).catch(() => {
				showFeedback('Error', 'Something went wrong to update your subscription. Please, try again', 'error')
			})
		}
	}, [token, currentSubscription, showFeedback, getOrganizationSubscriptions])

	useEffect(() => {
		getOrganizationSubscriptions()
	}, [getOrganizationSubscriptions])

	return useMemo(() => ({
		subscriptions,
		schedules,
		currentSubscription,
		isActiveSubscription,
		trialDaysLeft,
		isTrial: trialDaysLeft !== undefined,
		isPausedSubscription,
		cancelCurrentSubscription,
		updateSubscriptionSeats,
		updateSubscriptionPlan,
		subscriptionSchedule,
		setSubscriptionSchedule,
		isCurrentSubscriptionGrowth: isGrowthSubscription(currentSubscription),
		isCurrentSubscriptionScale: isScaleSubscription(currentSubscription),
		isLoadingSubscriptions
	}), [subscriptions, schedules, currentSubscription, isActiveSubscription, trialDaysLeft, isPausedSubscription, cancelCurrentSubscription, updateSubscriptionSeats, updateSubscriptionPlan,
		subscriptionSchedule, isLoadingSubscriptions])
}