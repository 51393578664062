import {FC, useCallback} from 'react'
import {Box, Chip, Tooltip, Typography} from '@mui/material'
import {FILE_UPLOAD_ERROR_MESSAGE, FileUpload} from '../../types/File'
import {useFilesContext} from '../../context/FilesContext'
import {FileUploadChipIcon} from './FileUploadChipIcon'
import {getHumanReadableFileSize} from '../../utils/fileUtils'
import DeleteIcon from '@mui/icons-material/Close'
import './FileUploadChips.scss'

type Props = {
    fileUploads: FileUpload[]
    align?: 'left' | 'right'
}

export const FileUploadChips: FC<Props> = ({
    fileUploads,
    align
}) => {
    const suffix = align === 'right' ? 'fileUploadChips--right' : 'fileUploadChips--left'
    return <Box className={`fileUploadChips ${suffix}`}>
        { fileUploads.map(fileUpload => <FileUploadChip key={fileUpload.metadata.id} fileUpload={fileUpload}/>) }
    </Box>
}

/** Amount of characters displayed at right after label has ellipsed */
const LABEL_RIGHT = 9

type FileUploadChipProps = {
    fileUpload: FileUpload
}

const FileUploadChip: FC<FileUploadChipProps> = ({
    fileUpload
}) => {
    const { removeFile, retryUpload } = useFilesContext()
    const { state, metadata, errorMessage} = fileUpload
    const label =
        state === 'error' ? errorMessage || 'upload error' :
        state === 'loading' ? 'uploading...' :
        metadata.name

    const labelStart = label.slice(0, label.length - LABEL_RIGHT)
    const labelEnd = label.slice(-LABEL_RIGHT)
    const labelSize = getHumanReadableFileSize(fileUpload.file.size, true)

    const clickable = state === 'error' && errorMessage !== FILE_UPLOAD_ERROR_MESSAGE.FILE_SIZE_ERROR

    const handleDelete = useCallback(() => {
        removeFile(fileUpload)
    }, [fileUpload, removeFile])

    const handleClick = useCallback(() => {
        if (clickable) {
            retryUpload(fileUpload)
        }
    },[clickable, fileUpload, retryUpload])

    return <Chip
        className='fileUploadChip'
        deleteIcon={<DeleteIcon/>}
        icon={<FileUploadChipIcon fileUpload={fileUpload}/>}
        color={state === 'error' ? 'error' : 'default'}
        label={<Box className='fileUploadChip_LabelContainer'>
            <Tooltip title={label}>
                <Box className='fileUploadChip_LabelTitleContainer'>
                    <Typography className='fileUploadChip_LabelTitleStart'>{labelStart}</Typography>
                    <Typography className='fileUploadChip_LabelTitleEnd'>{labelEnd}</Typography>
                </Box>
            </Tooltip>
            <Typography className='fileUploadChip_LabelSubtitle'>{labelSize}</Typography>
        </Box>}
        disabled={state === 'loading'}
        clickable={clickable}
        onClick={(handleClick)}
        onDelete={handleDelete} 
        />
}